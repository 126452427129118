<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <h1 class="title is-1">{{ projects.length }} Projects</h1>
        <button
          class="icon is-large button is-success"
          @click="addNewProject(project)"
        >
          <i class="fas fa-plus fa-large"></i>
        </button>
      </div>
    </div>

    <div class="container">
      <draggable v-model="projects" class="resource-box container" group="a">
        <div v-for="project in projects" :key="project.name">
          <div class="resource-section columns is-mobile">
            <div class="column">
              <h1 class="title is-3 project-title">{{ project.name }}</h1>
            </div>
            <div class="column">
              <div class="buttons is-large">
                <button
                  class="icon is-large button is-danger"
                  @click="removeProject(project)"
                >
                  <i class="fas fa-times fa-large"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/_variables.scss";

.project-title {
  font-size: 2.15rem;
  font-weight: 800;
  text-align: center;
  color: $primary;
}
</style>

<script>
// remooove for launch - import projectsJSON from "@/models/mocks/data/projects.json";
import draggable from "vuedraggable";

export default {
  name: "Projects",
  components: {
    draggable,
  },
  data() {
    return {
      projects: [],
    };
  },
  methods: {
    openToDo() {},
    removeProject() {},
  },
};
</script>
